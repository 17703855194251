





















import { defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
  components: {
    OAlertList: () => import('@/components/organisms/o-alert-list.vue'),
    OAlertListFuture: () => import('@/components/organisms/o-alert-list-future.vue')
  },

  setup() {
    const state = reactive({
      isLoading: false,
      tab: null
    })

    return { state }
  }
})
